import React from 'react'
import Figure from './figure'

const Usp = ({ text, image, compact = false, ...props }) => (
  <>
    {compact ? (
      <div className='flex flex-row items-center'>
        <div className='w-12 h-12 mr-4 rounded-full'>
          {image && image.asset && (
            <Figure node={image} />
          )}
        </div>

        <span className='font-bold'>
          {text}
        </span>
      </div>
    ) : (
      <div className='flex flex-row lg:flex-col items-center mb-4'>
        <div className='w-12 h-12 mr-4 lg:mb-4 rounded-full'>
          {image && image.asset && (
            <Figure node={image} />
          )}
        </div>

        <span className='font-bold'>
          {text}
        </span>
      </div>
    )}
  </>
)

export default Usp
