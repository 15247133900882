import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Usp from './usp'

const GlobalUSP = ({ compact = false, ...props }) => {
  const globalUSPResult = useStaticQuery(graphql`
    query shoppingOnboardingQuery {
      site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
        usp {
          image {
            asset {
              id
              fluid(maxWidth: 64) {
                ...GatsbySanityImageFluid
              }
            }
          }
          text
        }
      }
    }
  `)

  return (
    <div {...props}>
      {globalUSPResult.site.usp.map((usp, i) => (
        <Usp compact={compact} text={usp.text} image={usp.image} key={`global-usp-${i}`} />
      ))}
    </div>
  )
}

export default GlobalUSP
